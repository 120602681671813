<template>
  <CCard class="h-100">
    <CCardHeader class="d-flex align-items-center flex-wrap">
      <TMessage content="Pallets" bold class="h6 m-0" noTranslate />
      <TButton
        v-if="box.quantity_available_in_pallet"
        class="ml-auto"
        content="Assemble to pallets"
        variant="outline"
        icon="cil-arrow-right"
        :to="lodash.getReferenceLink('assemble-to-stock.box', box.id)"

      />
    </CCardHeader>
    <CCardBody>
      <CRow v-if="box.pivot_pallets && box.pivot_pallets.length">
        <CCol col="4" v-for="pallet in box.pivot_pallets" :key="pallet.id">
          <CCard accentColor="info" class="p-3">
            <TLink
              v-if="pallet.pallet_id"
              :content="pallet.pallet_id"
              :messageOptions="{ bold: true }"
              :to="lodash.getReferenceLink('assemble-to-stock.pallet', pallet.pallet_id)"
              class="h4 m-0"
            />
            <TMessage content="Quantity in pallets">
              <template #suffix
                >:
                <CBadge color="info" style="font-size: 13px">
                  {{ pallet.current_quantity }}
                </CBadge>
              </template>
            </TMessage>
          </CCard>
        </CCol>
      </CRow>
      <TMessageNoData v-else content="Not ordered in stock yet" />
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  props: {
    box: {
      type: Object,
    },
  }
};
</script>
