<template>
  <CCard>
    <CCardHeader class="d-flex align-items-center">
      <TMessage content="Box" bold>
        <template #suffix>
          {{ box.id }}
        </template>
      </TMessage>
      <SMessageBoxStatus :box="box" class="ml-1 my-auto" />
      <TButton
        v-if="editable"
        content="Add tags"
        variant="outline"
        icon="cil-plus"
        class="ml-auto"
        @click="showModalAddTags = true"
      />
      <TButtonDelete size="sm" variant="" class="ml-1" @click="deleteSKU" />
      <SModalAddTagSku :show.sync="showModalAddTags" />
    </CCardHeader>
    <CCardBody>
      <div v-if="box.tags && box.tags.length" class="d-flex flex-wrap mb-3">
        <TMessage content="Tags" bold class="mr-2 h5">
          <template #suffix>:</template>
        </TMessage>
        <div v-for="tag in box.tags" :key="tag.id" class="mr-1">
          <SMessageBoxTag :tag="tag" removable />
        </div>
      </div>

      <CRow>
        <CCol col="12" md="6" class="mb-3">
          <TTableAsForm
            :fields="boxFields"
            :data="box"
            :splitLeft="7"
            style="font-size: 16px"
            :addRowClasses="['pb-2']"
          >
            <template #updated_at="{ value }">
              <TMessageDateTime dateOnly :content="value" />
            </template>
            <template #closed_at="{ value }">
              <TMessageDateTime dateOnly :content="value" />
            </template>
            <template #number_days_storage="{ value }">
              <TMessageNumber :value="value" />
            </template>
            <template #length="{ value }">
              <TMessageNumber
                :value="value"
                :editable="editable"
                suffix="cm"
                @change="updateBox('length', $event)"
              />
            </template>
            <template #width="{ value }">
              <TMessageNumber
                :value="value"
                :editable="editable"
                suffix="cm"
                @change="updateBox('width', $event)"
              />
            </template>
            <template #height="{ value }">
              <TMessageNumber
                :value="value"
                :editable="editable"
                suffix="cm"
                @change="updateBox('height', $event)"
              />
            </template>
            <template #volume="{ value }">
              <TMessageVolume :value="value" />
            </template>
            <template #weight="{ value }">
              <TMessageNumber
                :value="value"
                :editable="editable"
                suffix="kg"
                @change="updateBox('weight', $event)"
              />
            </template>
            <template #duplicate="{ value }">
              <TMessageNumber
                :value="value"
                :editable="!box.is_parent_box && editable"
                @change="updateBox('duplicate', $event)"
              />
            </template>
            <template #sfa_id="{ value }">
              <TMessage v-if="value" :content="value" bold noTranslate />
            </template>
            <template #tracking>
              <TMessage
                v-if="box.sfa"
                :content="box.sfa.tracking_code"
                bold
                noTranslate
              />
            </template>
          </TTableAsForm>
        </CCol>
        <CCol col="12" md="6" class="mb-3">
          <TTableAsForm
            :fields="quantityFields"
            :splitLeft="7"
            :data="box"
            style="font-size: 16px"
            :addRowClasses="['pb-2']"
          >
          </TTableAsForm>
        </CCol>
        <CCol col="12" md="6" class="mb-3">
          <TTableAsForm
            v-if="refFields.length"
            title="Reference"
            :fields="refFields"
            :splitLeft="7"
            :data="box"
          >
            <template #box_parent_id="{ value }">
              <TLink
                v-if="value"
                :content="value"
                bold
                :to="lodash.getReferenceLink('box', value)"
              />
            </template>
            <template #box_ref_id="{ value }">
              <TLink
                v-if="value"
                :content="value"
                bold
                :to="lodash.getReferenceLink('box', value)"
              />
            </template>
          </TTableAsForm>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  props: {
    box: {
      type: Object,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      boxFields: [
        { key: "length", label: "Length" },
        { key: "width", label: "Width" },
        { key: "height", label: "Height" },
        { key: "volume", label: "Volume" },
        { key: "weight", label: "Weight" },
        { key: "duplicate", label: "Quantity" },
        { key: "sfa_id", label: "SFA" },
        { key: "tracking", label: "Tracking Id" },
        { key: "updated_at", label: "Updated at" },
        { key: "closed_at", label: "Packed date" },
        { key: "number_days_storage", label: "Total days of storage" },
      ],
      quantityFields: [
        {
          key: "quantity_in_inventory",
          label: "Quantity in stock",
        },
        {
          key: "quantity_inventory",
          label: "Inventory number",
        },
        { key: "quantity_in_pallets", label: "Quantity in pallets" },
        { key: "quantity_of_owners", label: "Distributed quantity" },
        { key: "quantity_in_picker", label: "Quantity in cont" },
        { key: "quantity_out_picker", label: "Quantity out cont" },
        { key: "quantity_outbound", label: "Quantity outbound" },
      ],
      refFields: [],
      showModalAddTags: false,
    };
  },
  watch: {
    box: {
      immediate: true,
      handler(box) {
        this.refFields = [];
        if (box.box_ref_id) {
          this.refFields.push({ key: "box_ref_id", label: "Reference" });
        }
        if (box.box_parent_id) {
          this.refFields.push({ key: "box_parent_id", label: "Combine box" });
        }
      },
    },
  },
  computed: {
    hasChangeListener() {
      return this.$listeners && this.$listeners.change;
    },
  },
  methods: {
    updateBox($field, $value) {
      this.$store.dispatch("warehouse.boxes_management.detail.update", {
        [$field]: $value,
      });
    },
    deleteSKU() {
      this.$store
        .dispatch("warehouse.boxes_management.detail.delete")
        .then(() => {
          this.$router.push({ query: { ...this.$route.query, box_id: "" } });
        });
    },
  },
};
</script>
