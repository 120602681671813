<template>
  <div class="d-flex w-100">
    <TInputSimpleSearch
      placeholder="Search sku"
      :value.sync="search"
      @update:value="triggerSearch"
      class="w-100 mr-1"
    />
    <SButtonFilter
      :active="!lodash.isEmpty(query)"
      :badge="lodash.size(query)"
      class="ml-auto my-auto"
      @click="show = true"
    />

    <TModal title="Filter" :show="show" @update:show="show = $event">
      <template #actions>
        <SButtonClearFilter
          @click="clearFilter"
          :disabled="loading || lodash.isEmpty(allQuery)"
        />
        <SButtonSearchFilter :disabled="loading" @click="onFilter" />
      </template>

      <CRow>
        <CCol col="12" lg="6" md="6" class="mb-3">
          <TInputText
            label="Tracking"
            placeholder="Tracking"
            :value.sync="filter.tracking"
            @keydown.enter.native="onFilter"
            prependAll
            noFetchStore
          />
        </CCol>
        <CCol col="12" lg="6" md="6" class="mb-3">
          <SSelectSfa label="SFA Id" :value.sync="filter.sfa" prependAll />
        </CCol>
        <CCol col="12" lg="6" md="6" class="mb-3">
          <TInputText
            label="Goods"
            placeholder="Product Id"
            :value.sync="filter['items.product_id']"
          />
        </CCol>
        <CCol col="12" lg="6" md="6" class="mb-3">
          <SSelectBoxType
            label="Type"
            :value.sync="boxType"
            @change="selectBoxType"
          />
        </CCol>
        <CCol col="12" lg="6" md="6" class="mb-3">
          <SSelectBoxTags label="Tags" :value.sync="filter['tags.id']" />
        </CCol>
        <CCol col="12" lg="6" md="6" class="mb-3">
          <TInputDateTimeRange
            label="Created at"
            :value.sync="dateRange"
            @update:value="setDateRangeFilter"
          />
        </CCol>
      </CRow>
    </TModal>
  </div>
</template>

<script>
import filter from "../mixins/filter";
export default {
  mixins: [filter],
  data() {
    return {
      blockParams: ["tab", "action", "box_id"],
      searchFilter: "id",
      betweenDates: "created_at",
      store: "warehouse.boxes_management",
      boxType: "",
      type: ["is_parent_box", "is_child_box"],
    };
  },
  mounted() {
    this.type.forEach((t) => {
      if (this.lodash.has(this.filter, t)) this.boxType = t;
    });
  },
  watch: {
    filter(obj) {
      if (!this.lodash.has(obj, this.boxType)) this.boxType = "";
    },
  },
  methods: {
    setDateRangeFilter({ start, end }) {
      this.filter_between[this.betweenDates] = `${start},${end}`;
    },
    selectBoxType(value) {
      this.filter = this.lodash.omit(this.filter, this.type);
      if (!value.id) return;
      this.filter[value.id] = 1;
    },
    getFilterFields() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filter_between = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      if (!this.lodash.has(this.filter, this.boxType)) this.boxType = "";
      const boxType = this.boxType ? { [`filter[${this.boxType}]`]: 1 } : "";
      const filterFields = this.lodash.pickBy({
        ...boxType,
        ...filter,
        ...filter_between,
        [`filter[${this.searchFilter}]`]: this.$route.query.search,
      });
      return filterFields;
    },
    clearFilter() {
      this.filter = {};
      this.filter_between = {};
      this.boxType = "";
      this.$router.push({ query: this.blockQuery }).then(() => {
        this.onFilter();
      });
      this.dateRange = {
        start: null,
        end: null,
      };
    },
  },
};
</script>
