<template>
  <CCard class="h-100">
    <CCardHeader class="d-flex align-items-center flex-wrap">
      <TMessage content="Distribution" bold class="h6 m-0" />
      <SButtonNextToDistribution
        class="ml-auto"
        v-if="box.sfa && box.quantity_inventory"
        :query="`search=${box.sfa.tracking_code}`"
      />
    </CCardHeader>
    <CCardBody>
      <CRow v-if="box.owners && box.owners.length">
        <CCol
          col="4"
          v-for="order in box.owners"
          :key="order.id"
          class="mb-3"
        >
          <CCard accentColor="info" class="p-3">
            <TLink
              v-if="order.order_id"
              :content="order.order_id"
              :messageOptions="{ bold: true }"
              :to="lodash.getReferenceLink('assemble-to-order', order.order_id)"
              class="h4 m-0"
            />
            <TMessage content="Distribution quantity">
              <template #suffix
                >:
                <CBadge color="info" style="font-size: 13px">
                  {{ order.quantity }}
                </CBadge>
              </template>
            </TMessage>
          </CCard>
        </CCol>
      </CRow>
      <TMessageNoData v-else content="Not yet distributed" />
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  props: {
    box: {
      type: Object,
    }
  },
};
</script>
