<template>
  <CCard v-if="box.children && box.is_parent_box">
    <CCardHeader class="d-flex flex-wrap align-items-center">
      <div class="d-flex align-items-center">
        <TMessage content="Child box" bold />
        <TSpinner :loading="loading" />
      </div>
    </CCardHeader>
    <CCardBody>
      <TMessageNoData content="No SKU" v-if="!box.children.length" />
      <CRow v-else class="d-flex align-items-stretch">
        <SCardSku
          v-for="box in box.children"
          :key="box.id"
          :box="box"
          refresh
          class="my-3 mx-3"
          removable
          @remove="onRemove(box)"
        />
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  props: {
    box: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    onRemove(box) {
      this.$emit("remove", box);
    },
  },
};
</script>
