<template>
  <CRow class="mb-5">
    <CCol lg="3">
      <TAside>
        <CListGroup>
          <CListGroupItem>
            <FilterForBox :queries.sync="query" />
          </CListGroupItem>
          <CListGroupItem
            v-for="(box, index) in boxes_available"
            :key="`${index}${box.id}`"
            :color="box_id_selected == box.id ? 'primary' : 'secondary'"
            :to="_.getAppendRouteQuery($route, { box_id: box.id })"
          >
            <SCardBox
              :box="box"
              :parent="box.is_parent_box"
              :showVolume="false"
            />
          </CListGroupItem>
          <CListGroupItem v-if="!boxes_available.length">
            <TMessageNotFound />
          </CListGroupItem>
          <CListGroupItem v-if="boxes_available_loading">
            <TSpinner :loading="boxes_available_loading" />
          </CListGroupItem>
          <SButtonLoadmore store="warehouse.boxes_management" />
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="9">
      <CRow v-if="box_id_selected">
        <CCol col="12">
          <!-- Box info -->
          <CardBoxGeneralInfo :box="box_selected" editable />
        </CCol>
        <CCol v-if="!box_selected.is_parent_box">
          <!-- Box product -->
          <CardBoxDetail
            creatable
            :box="box_selected"
            removable
            editable
            @updated="fetchBox()"
          />
        </CCol>
        <CCol col="12" v-if="box_id_selected">
          <!-- Card box childrens -->
          <CardBoxChildrens
            :box="box_selected"
            :loading="boxes_available_loading"
            @remove="removeChidren($event)"
          />
        </CCol>
      </CRow>
      <CRow v-if="box_id_selected">
        <CCol col="12" class="mb-3" v-if="!box_selected.is_child_box">
          <!-- Card box pallets -->
          <CardBoxPallet :box="box_selected" />
        </CCol>
        <CCol col="12" class="mb-3" v-if="!box_selected.is_parent_box">
          <!-- Card box distribution -->
          <CardBoxDistribution :box="box_selected" />
        </CCol>
        <CCol col="12">
          <SCardLog :logableId="box_id_selected" logableType="box" />
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from "vuex";
import CardBoxDetail from "../../components/CardBoxDetail.vue";
import CardBoxGeneralInfo from "../../components/CardBoxGeneralInfo.vue";
import CardBoxChildrens from "../../components/CardBoxChildrens.vue";
import CardBoxPallet from "../../components/CardBoxPallet.vue";
import CardBoxDistribution from "../../components/CardBoxDistribution.vue";
import FilterForBox from "../../components/FilterForBox.vue";
import filterable from "../../mixins/filterable";

export default {
  components: {
    CardBoxDetail,
    CardBoxGeneralInfo,
    CardBoxChildrens,
    CardBoxPallet,
    CardBoxDistribution,
    FilterForBox,
  },
  mixins: [filterable],
  mounted() {
    if (this.lodash.isEmpty(this.query)) {
      this.$store.dispatch("warehouse.boxes_management.fetch.if-first-time");
    } else
      this.$store.dispatch(
        "warehouse.boxes_management.apply-query",
        this.query
      );
  },
  watch: {
    box_id_selected: {
      immediate: true,
      handler(id) {
        if (id) {
          this.fetchBox();
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      boxes_available: "warehouse.boxes_management.list",
      boxes_available_loading: "warehouse.boxes_management.loading",
    }),
    box_id_selected() {
      return this.$route.query.box_id;
    },
    box_selected() {
      return this.$store.getters["warehouse.boxes_management.detail"];
    },
  },
  methods: {
    fetchBox() {
      this.$store.dispatch(
        "warehouse.boxes_management.detail.fetch",
        this.box_id_selected
      );
    },

    removeChidren(box) {
      this.$store
        .dispatch("warehouse.boxes_management.update", {
          id: box.id,
          attributes: {
            box_parent_id: "",
          },
        })
        .then(() => {
          this.fetchBox();
        });
    },
  },
};
</script>
